<template>
  <div>
    <!-- Start Page Title -->
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h2>{{ $t('Contact Us') }}</h2>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../assets/img/shape2.png" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../assets/img/shape3.png" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../assets/img/shape4.png" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../../../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../assets/img/shape4.png" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../assets/img/shape4.png" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../assets/img/shape2.png" alt="shape" />
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Contact Info Area -->
    <section class="contact-info-area ptb-80">
      <div class="container">
        <div class="row justify-content-sm-center">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="mail"></feather>
              </div>
              <h3>{{ $t('Our Email') }}</h3>
              <!-- <p><a href="#">admin@startp.com</a></p> -->
              <p>
                <a href="mailto:info@startblock.tech">info@startblock.tech</a>
              </p>
            </div>
          </div>
          <!--
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="map-pin"></feather>
                            </div>
                            <h3>Visit Here</h3>
                            <p>27 Division St, New York, NY 10002, United States of America</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="phone"></feather>
                            </div>
                            <h3>Call Here</h3>
                            <p><a href="#">+123 456 7890</a></p>
                            <p><a href="#">+241 452 4526</a></p>
                        </div>
                    </div>
          -->
        </div>
      </div>
    </section>
    <!-- End Contact Info Area -->

    <!-- Start Contact Area -->
    <section class="contact-area ptb-80 pt-0">
      <div class="container">
        <div class="section-title">
          <h2>{{ $t('Get in contact with us') }}</h2>
          <div class="bar"></div>
          <p>{{ $t('We will be delighted to help you!') }}</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12">
            <img src="../../../assets/img/1.png" alt="image" />
          </div>

          <div class="col-lg-6 col-md-12">
            <form @submit.prevent id="contactForm">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input
                      v-model="name"
                      type="text"
                      name="name"
                      id="name"
                      class="form-control"
                      required
                      :data-error="$t('Please enter your name')"
                      :placeholder="$t('Name')"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input
                      v-model="email"
                      type="email"
                      name="email"
                      id="email"
                      class="form-control"
                      required
                      :data-error="$t('Give us your email')"
                      :placeholder="$t('E-mail')"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-6">
                  <div class="form-group">
                    <input
                      v-model="phone"
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      required
                      :data-error="$t('Enter your phone number')"
                      class="form-control"
                      :placeholder="$t('Phone')"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-6">
                  <div class="form-group">
                    <input
                      v-model="issue"
                      type="text"
                      name="msg_subject"
                      id="msg_subject"
                      class="form-control"
                      required
                      :data-error="$t('Why are you contacting us?')"
                      :placeholder="$t('Subject')"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      v-model="message"
                      name="message"
                      class="form-control"
                      id="message"
                      cols="30"
                      rows="5"
                      required
                      :data-error="$t('Write your message')"
                      :placeholder="$t('Your message')"
                    ></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button @click="checkForm" type="submit" class="btn btn-primary"> {{ $t('Send') }}</button>
                  <div id="msgSubmit" class="h3 text-center hidden">{{sent}}</div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Area -->
  </div>
</template>

<script>
import { db } from "@/main.js";
export default {
  name: "Contact",
  data() {
    return {
      sent: null,
      name: null,
      email: null,
      phone: null,
      issue: null,
      message: null,
    };
  },
  methods: {
    checkForm() {
      if (this.name && this.email && this.phone && this.issue && this.message) {
        db.collection("contact")
          .doc(this.email)
          .set({
            name: this.name,
            phone: this.phone,
            issue: this.issue,
            message: this.message,
            read: false,
            timestamp: Date.now(),
          })
          .then(() => {
            this.sent = "Recibido";
            setTimeout(() => {
              this.name = null;
              this.email = null;
              this.phone = null;
              this.issue = null;
              this.message = null;
              this.sent = null
            }, 5000);
          });
      }
    },
  },
};
</script>
